.main{
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.column{
    display: flex;
    flex-direction: column;
}

.flex{
    display: flex;
    align-items: center;
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.btn-more{
    width: 5.9vw;
    background-color: #1D9088;
    border-radius: 0.26vw;
    height: 2.4vw;
    border: none;
    color: white;
    font-size: 1.04vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    margin-bottom: 2vw;
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.underlinenone{
    text-decoration: none;
}

h1{
    line-height: 1.2;
}

p{
    line-height: 1.2;
}

span{
    line-height: 1.2;
}

h2{
    line-height: 1.2;
}

.fonttitlegeo{
    font-size: 2vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-bottom: 0.5vw;
}

.font-mtavr{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-bottom: 0.5vw;
}

.font-mkhedr{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    margin-bottom: 0.5vw;
    margin-top: 0;
}

.margintop0{
    margin-top: 0!important;
}

.flex-start{
    width: 68.75vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-end{
    width: 68.75vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content-404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.span-404{
   font-size: 10vw;
   color: #1D9088;
   font-family: "BPG Nino Mtavruli", sans-serif;
   font-weight: 900;
   margin-bottom: -2vw;
}

.not-fnd{
    font-size: 1.5vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.timer-span{
    font-size: 1vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    width: 40vw;
    text-align: center;
    margin-top: 1vw;
}

.countdown label{
    color: red;
    font-size: 1vw;
    font-weight: 900;
}

.margintopstart{
    margin-top: 4vw;
}

.fontNoto{
    font-family: 'Noto Sans Display'!important;
    font-weight: 700!important;
}

.fontNoto img{
    margin-bottom: 0!important;
}

.fontNotoDate{
    font-family: 'Noto Sans Display'!important;
    font-weight: 500!important;
}

@media only screen and (max-width: 650px) {

    .fonttitlegeo{
        font-size: 5vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-bottom: 0.5vw;
    }

    .margintopstart{
        margin-top: 30vw;
    }

    .main{
        min-height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .column{
        display: flex;
        flex-direction: column;
    }
    
    .flex{
        display: flex;
        align-items: center;
    }
    
    .flex-center{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .btn-more{
        width: 5.9vw;
        background-color: #1D9088;
        border-radius: 0.26vw;
        height: 2.4vw;
        border: none;
        color: white;
        font-size: 1.04vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        margin-bottom: 2vw;
        margin-top: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .underlinenone{
        text-decoration: none;
    }
    
    h1{
        line-height: 1.2;
    }
    
    p{
        line-height: 1.2;
    }
    
    span{
        line-height: 1.2;
    }
    
    h2{
        line-height: 1.2;
    }
    
    .font-mtavr{
        font-size: 1.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-bottom: 0.5vw;
    }
    
    .font-mkhedr{
        font-size: 1.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
        margin-bottom: 0.5vw;
        margin-top: 0;
    }
    
    .margintop0{
        margin-top: 0!important;
    }
    
    .flex-start{
        width: 84vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    .flex-end{
        width: 84vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .fontNoto{
        font-family: 'Noto Sans Display'!important;
    }

}



