.react-calendar__navigation{
    height: 2.3vw!important;
    background-color: white!important;
}

.react-calendar__navigation__prev2-button{
    display: none!important;
}

.react-calendar__navigation__next2-button{
    display: none!important;
}

.react-calendar{
    width: 21.8vw;
    height: 24.9vw;
    margin-bottom: 2vw;
    border: none;
} 


.react-calendar span{
    color: #00A99D ;
    font-size: 0.93vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.react-calendar__navigation__prev-button{
   width: 2.3vw;
   height: 2.3vw;
   box-shadow: 0 0 3px #37424B ;
   color: #00A99D;
   font-size: 2vw;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 0.4vw;
   padding-left: 0.4vw;
}

.react-calendar__navigation__next-button{
    width: 2.3vw;
    height: 2.3vw;
    box-shadow: 0 0 3px #37424B ;
    color: #00A99D;
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.4vw;
    padding-left: 0.4vw;
 }

 .react-calendar__month-view__weekdays{
     background-color: #E9E9F0;
     height: 3.125vw;
 }

 .react-calendar__month-view__weekdays__weekday{
     display: flex;
     align-items: center;
     justify-content: center;
    
 }

 .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
    font-size: 0.85vw;
    color: #A3A6B4;
    font-family: "BPG Nino Mtavruli", sans-serif;
 } 

 .react-calendar__tile--active{
     background-color: #00A99D;
 }

 .react-calendar__tile--active:hover{
    background-color: #018a81;
}

.react-calendar__tile--active abbr{
    color: white!important;
}

.react-calendar__tile--active:enabled:hover{
    background-color: #018a81;
}

.react-calendar__tile--active:enabled{
    background-color: #018a81!important;
}

.react-calendar__tile abbr{
    font-size: 0.9vw;
    color: #43425D;
}

 .react-calendar__tile{
     width: 3.126vw;
     height: 3.126vw;
 }

 .react-calendar__month-view__days__day--neighboringMonth abbr{
    color: #ffffff!important;
}

.react-calendar__tile--now:enabled {
    background-color: rgba(0, 169, 157, .4);
}

    @media only screen and (max-width: 650px) {
        
    }