.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-footer{
    width: 50vw;
    border-top: 1px solid #37424B;
    height: 7.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-contact-div{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 19.27vw;
    height: 3.125vw;
}

.socials-grid{
    display: grid;
    grid-template-columns: auto auto;
}

.socials-grid img{
    width: 1.04vw;
    height: 1.04vw;
}

.socials-grid a{
    width: 1.04vw;
    height: 1.04vw;
}

.socials-grid > * + * {
    margin-right: 0.4vw;
    margin-bottom: 0.4vw;
}

.footer-text-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 15.6vw;
    height: 2.825vw;
}

.footer-text-div span{
    color: #37424B;
    font-size: 0.729vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 600;
}

.footer-text-div a{
    color: #37424B;
    font-size: 0.729vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 600;
    text-decoration: none;
    margin-right: 1vw;
}

.bottom-footer{
    width: 100%;
    height: 3.125vw;
    background-color: #37424B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-footer span{
    font-size: 0.729vw;
    color: white;
    font-family: "BPG Nino Mtavruli", sans-serif;
}

.biuleteni{
    font-size: 1.56vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 600;
    margin-bottom: 1vw;
}

.input-subscribe{
    width: 23.3vw;
    height: 2.6vw;
    border: none;
    box-shadow: 0 0 1px #37424B;
    font-size: 1vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 600;
    padding-left: 1.5vw;
}

.btn-subscribe{
    background-color: #00A99D;
    width: 7.8vw;
    height: 2.8vw;
    border: none;
    margin-left: 1.5vw;
    color: white;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    font-size: 1vw;
    padding-top: 0.2vw;
}

.footer-sub{
    width: 68.75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #37424B;
    margin-top: 2vw;
}

.flex-biuleteni{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 650px) {


    .footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .top-footer{
        width: 84vw;
        border-top: 1px solid #37424B;
        height: unset;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .footer-contact-div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84vw;
        height: 26vw;
    }
    
    .socials-grid{
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .socials-grid img{
        width: 5vw;
        height: 5vw;
    }
    
    .socials-grid a{
        width: 5vw;
        height: 5vw;
    }
    
    .socials-grid > * + * {
        margin-right: 2vw;
        margin-bottom: 2vw;
    }
    
    .footer-text-div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 70vw;
        height: 12.5vw;
    }
    
    .footer-text-div span{
        color: #37424B;
        font-size: 3.4vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 600;
    }
    
    .footer-text-div a{
        color: #37424B;
        font-size: 3.4vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 600;
        text-decoration: none;
        margin-right: 1vw;
        margin-left: 6vw;
    }
    
    .bottom-footer{
        width: 100%;
        height: 15vw;
        background-color: #37424B;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bottom-footer span{
        font-size: 3.5vw;
        margin-top: 1vw;
        color: white;
        font-family: "BPG Nino Mtavruli", sans-serif;
    }
    
    .biuleteni{
        font-size: 6vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 600;
        margin-bottom: 1vw;
        text-align: center;
    }
    
    .input-subscribe{
        width: 84vw;
        height: 12.5vw;
        border: none;
        box-shadow: 0 0 1px #37424B;
        font-size: 5vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 600;
        padding-left: 1.5vw;
    }
    
    .btn-subscribe{
        background-color: #00A99D;
        width: 37.5vw;
        height: 12.5vw;
        border: none;
        margin-top: 4vw;
        color: white;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        font-size: 5vw;
        padding-top: 1.2vw;
        margin-bottom: 5vw;
    }
    
    .footer-sub{
        width: 84vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #37424B;
        margin-top: 2vw;
    }

    .flex-biuleteni{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}