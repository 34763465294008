.nav{
    width: 100%;
    height: 4.16vw;
    box-shadow: 0 0 7px #707070;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
    background-color: white;
}

.nav-logo-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 10.62vw;
    margin-left: 2.08vw;
}

.nav-logo{
    width: 2.5vw;
}

.nav-logo-text{
     margin-top: 0.4vw;
     width: 6.9vw;
}

.btn-lang{
    background-color: white;
    border: none;
    font-size: 0.729vw;
    color: #00A99D;
    font-family: 'Noto Sans Display';
    font-weight: 900;
    margin-right: 2vw;
    display: flex;
    justify-content: center;
    text-align: left;
    width: 3.4vw;
    text-decoration: none;
}

.nav-lang{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lang-hr{
    width: 2px;
    height: 0.9vw;
    background-color: #00A99D;
}

.search-icon{
    width: 0.83vw;
    margin-right: 1vw;
    cursor: pointer;
}

.nav-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.1vw;
    width: 28vw;
    margin-left: 37vw;
    margin-bottom: 0;
    list-style: none;

}

.nav-links li:hover .hover{
   color: #00A99D;
}

.Nav-item{
    font-size: 0.83vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5vw;
    line-height: 1;
}

.hover{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hover:hover{
    text-decoration: underline!important;
}

.link_active{
    position: relative;
}


.link_active::before{
    content: "";
    background-color: #00A99D;
    height: 3px;
    top: 2.43vw;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 100;
    cursor:crosshair;
}



.Nav-item a{
    text-decoration: none;
    color:#37424B;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.Nav-item span{
    text-decoration: none;
    color:#37424B;
    position: relative;
}

.Nav-item a:hover{
    color:#00A99D;
    text-decoration: underline;
}

.Nav-item span:hover{
    color:#00A99D;
}

.active{
    color: #00A99D!important;
    text-decoration: underline!important;
}

.none{
    display: none;
}

.hover-nav{
    list-style: none;
    position: absolute;
    top: 4.1vw;
    left: 0;
    display: flex;
    width: 102vw;
    height: 3.125vw;
    box-shadow: 0 5px 5px -5px #707070;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    border-top: 0.5px solid #707070;

}

.hover-nav.clicked{
    display: none;
}

.hover-nav li{
    margin-right: 1vw;
}

.bottomnav-div{
    display: flex;
    margin-right: 17vw;
}

.search-inp{
    width: 37vw;
    height: 2vw;
    box-shadow: 0 0 2px #37424B;
    border: none;
    margin-right: 1.5vw;
    color: #37424B;
    font-size: 1vw;
    font-weight: 500;
    padding-left: 1vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
}

.btn-search{
    background-color: transparent;
    border: none;
    color: #37424B;
    font-size: 0.83vw;
    margin-right: 6.4vw;
    font-weight: 900;
    font-family: "BPG Nino Mtavruli", sans-serif;
}

.btn-search:hover{
    color: #00A99D;
}


@media only screen and (max-width: 650px) {

    .mobilenavmenu{
        width: 7.5vw;
        margin-right: 7.5vw;
        cursor: pointer;
    }

    .search-inp{
        width: 55vw;
        height: 10vw;
        box-shadow: 0 0 2px #37424B;
        border: none;
        color: #37424B;
        font-size: 4vw;
        font-weight: 500;
        padding-left: 1vw;
        font-family: "BPG Nino Mkhedruli", sans-serif;
    }

    .btn-search{
        background-color: transparent;
        border: none;
        color: #37424B;
        font-size: 0.83vw;
        margin-right: 6.4vw;
        font-weight: 900;
        font-family: "BPG Nino Mtavruli", sans-serif;
    }
    
    .btn-search:hover{
        color: #00A99D;
    }

    .mobile-nav-topic-div{
        margin-top: 2vw;
        display: flex;
        width: 60vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobile-nav-topic-div-sublinks{
        display: flex;
        width: 60vw;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 14vw;
    }

    .mobile-nav-topic-div-sublinks a{
        font-size: 4vw;
        color: #37424B;
        font-weight: 900;
        font-family: "BPG Nino Mtavruli", sans-serif;
        text-decoration: none;
        margin-top: 2vw;
    }

    .mobile-nav-topic-flex{
        display: flex;
        width: 40vw;
        justify-content: flex-start;
        align-items: center;
    }

    .mobile-nav-topic-flex span{
        margin-left: 4vw;
        font-size: 7vw;
        margin-bottom: 1.5vw;
    }

    .mobile-nav-hr{
        height: 1px;
        width: 86vw;
        background-color: #00A99D;
        display: flex;
        margin-top: 3vw;
    }

    .mobile-search-lang-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 86vw;
        margin-top: 4vw;
    }

    .mobile-nav-topic-flex a{
        margin-left: 4vw;
        font-size: 4vw;
        text-decoration: none;
        color: #37424B;
        font-weight: 900;
        font-family: "BPG Nino Mtavruli", sans-serif;
    }

    .mobile-nav-show{
        position: fixed;
        top: 20.1vw;
        left: 0;
        right: 0;
        background-color: white;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-right: 7.5vw;
        padding-left: 7.5vw;
        padding-top: 4vw;
        z-index: 99;
    }

    .nav{
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 20vw;
        box-shadow: 0 0 7px #707070;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        background-color: white;
    }
    
    .nav-logo-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        margin-left: 7.5vw;
        margin-bottom: 1vw;
    }
    
    .nav-logo{
        width: 11.25vw;
    }
    
    .nav-logo-text{
         margin-top: 2.5vw;
         margin-left: 4vw;
         width: 35vw;
    }
    
    .btn-lang{
        background-color: white;
        border: none;
        font-size: 4vw;
        color: #00A99D;
        font-family: 'Noto Sans Display';
        font-weight: 900;
        display: flex;
        justify-content: center;
        text-align: left;
        margin-left: 5vw;

    }
    
    .nav-lang{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .lang-hr{
        width: 2px;
        height: 6vw;
        background-color: #00A99D;
    }
    
    .search-icon{
        width: 4vw;
        margin-right: 4vw;
        cursor: pointer;
    }
    
    .nav-links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.1vw;
        width: 28vw;
        margin-left: 37vw;
        margin-bottom: 0;
        list-style: none;
    }
    
    .nav-links li:hover .hover{
       color: #00A99D;
    }
    
    .Nav-item{
        font-size: 0.83vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0.5vw;
    }
    
    .hover{
        pointer-events: none!important;
    }
    
    .hover:hover{
        text-decoration: underline!important;
    }
    
    
    
    .Nav-item a{
        text-decoration: none;
        color:#37424B;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    
    .Nav-item span{
        text-decoration: none;
        color:#37424B;
    }
    
    .Nav-item a:hover{
        color:#00A99D;
        text-decoration: underline;
    }
    
    .Nav-item span:hover{
        color:#00A99D;
    }
    
    .active{
        color: #00A99D!important;
        text-decoration: underline!important;
    }
    
    .none{
        display: none;
    }
    
    .hover-nav{
        list-style: none;
        position: absolute;
        top: 4.5vw;
        left: 0;
        display: flex;
        width: 102vw;
        height: 3.125vw;
        box-shadow: 0 5px 5px -5px #707070;
        align-items: center;
        justify-content: flex-end;
        background-color: white;
    
    }
    
    .hover-nav.clicked{
        display: none;
    }
    
    .hover-nav li{
        margin-right: 1vw;
    }
    
    .bottomnav-div{
        display: flex;
        margin-right: 16vw;
    }
    

}

