.banks-grid{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    width: 68.75vw;
    margin-top: 3vw;
}

.margintopbanks{
    margin-top: 6vw;
}

.banks-grid > * + * {
    margin-bottom: 4.16vw;
}

.bank-modal-outer{
        position: fixed;
        inset: 0 0 0 0;
        background-color: rgb(255,255,255,0.8);
        z-index: 2000;
        margin-bottom: 0!important;
}

.bank-modal{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 2001;
    transform: translate(-50%,-50%);
    width: 45.8vw;
    height: 36.45vw;
    background-color: white;
    box-shadow: 0 0 2px #707070;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bank-modal img{
    width: 26.04vw;
    height: 15.62vw;
    object-fit: contain;
    box-shadow: 0 0 2px #707070;
    margin-top: 3.6vw;
}

.bank-card{
    width: 18.75vw;
    height: 16.6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px #707070;
    cursor: pointer;
}

.bank-card img{
    width: 16.6vw;
    height: 11.45vw;
    object-fit: contain;
    margin-top: 0.8vw;
    box-shadow: 0 0 2px #707070;
}

.bank-card h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 1.4vw;
}

.bank-modal h2{
    font-size: 1.6vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 1.5vw;
}

.bank-modal p {
      font-size: .8vw;
      width: 50%;
      text-align: center;
      margin-top: 0;
      font-family: "BPG Nino Mtavruli", sans-serif;
}

.bank-modal a{
    font-size: 1vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
}

.new-member-h4{
    margin-top: 0vw;
    font-size: 1.04vw!important;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500!important;
    text-align: center;
}
.forp span{
    width: 70%;
    height: 13vw;
    overflow-y: scroll;
}

.forp span::-webkit-scrollbar{
    width: 6px;
 }

.forp span::-webkit-scrollbar-thumb{
    background-color: #00A99D;
    opacity: 0.7;
  }

.forp p{
    width: unset!important;
}

/* .bank-scroll{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y:auto
} */

@media only screen and (max-width: 650px) {

    .bank-modal p {
        font-size: 3vw;
        width: 80%;
    }
    
    .bank-modal a{
        font-size: 3vw;
    }
    .banks-grid{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 86vw;
        margin-top: 3vw;
    }
    
    .margintopbanks{
        margin-top: 6vw;
    }
    
    .banks-grid > * + * {
        margin-bottom: 4.16vw;
    }
    
    .bank-modal-outer{
            position: fixed;
            inset: 0 0 0 0;
            background-color: rgb(255,255,255,0.8);
            z-index: 2000;
            margin-bottom: 0!important;
            display: flex;
            justify-content: center;
            align-items: center;
    }
    
    .bank-modal{
        z-index: 2001;
        width: 86vw;
        height: 120vw;
        background-color: white;
        box-shadow: 0 0 2px #707070;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .bank-modal img{
        width: 72vw;
        height: 50vw;
        object-fit: contain;
        box-shadow: 0 0 2px #707070;
        margin-top: 3.6vw;
    }
    
    .bank-card{
        width: 86vw;
        height: 78vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 2px #707070;
        cursor: pointer;
        margin-bottom: 5vw;
    }
    
    .bank-card img{
        width: 72vw;
        height: 50vw;
        object-fit: contain;
        margin-top: 0.8vw;
        box-shadow: 0 0 2px #707070;
    }
    
    .bank-card h2{
        font-size: 5.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 3.4vw;
    }
    
    .bank-modal h2{
        font-size: 5.6vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        margin-top: 3.5vw;
    }
}

