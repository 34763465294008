.main-layout{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 68.75vw;
    margin-top: 2vw;
}

@media only screen and (max-width: 650px) {
    .main-layout{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 86vw;
        margin-top: 2vw;
    }
}