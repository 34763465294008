.contact-between{
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 68.75vw;
    margin-top: 2vw;
    margin-bottom: 5vw;
}

.map-container{
    width: 30vw;
    height: 22.91vw;
}

.google-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34.37vw;
    height: 25vw;
    box-shadow: 0 0 2px #707070;
}

.form-side{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 4.42vw;
    justify-content: flex-start;
    width: 34.84375vw;
    height: 28.85416vw;
}

.contact-form-flex{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 1vw;
}

.contact-label{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    text-align: end;
    margin-right: 1vw;
    margin-top: 1.15vw;
}

.contact-input{
    width: 24vw;
    height: 3.125vw;
    /* box-shadow: 0 0 2px #707070; */
    border: 1px solid #707070;
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    padding-left: 1vw;
}

.contact-textarea{
    width: 24vw;
    height: 11.5vw;
    border: 1px solid #707070;
    resize: none;
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    padding-left: 1vw;
    padding-top: 1vw;
}

input:focus-visible{
    outline: none;
}

textarea:focus-visible{
    outline: none;
}

.flex-send-button{
    width: 25vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.none{
    display: none;
}

.window-sent{
    width: 19vw;
    height: 4.5vw;
    background-color: white;
    box-shadow: 0 0 5px #37424B;
    margin-top: 6vw;
    display: flex;
    border-radius: 0.1vw;
    justify-content: center;
    align-items: center;
    font-size: 1.6vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    text-align: center;
    padding: 4vw;
}

.btn-send{
    width: 8.33vw;
    height: 2.6vw;
    background-color: #00A99D;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1.04vw;
    color: white;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

@media only screen and (max-width: 650px) {
    .contact-between{
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 86vw;
        margin-top: 2vw;
        margin-bottom: 5vw;
    }
    
    .map-container{
        width: 76vw;
        height: 67vw;
    }
    
    .google-div{
        margin-top: 8vw;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86vw;
        height: 76vw;
        box-shadow: 0 0 2px #707070;
    }
    
    .form-side{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right:0vw;
        justify-content: flex-start;
        width: 84vw;
        height: unset;
    }
    
    .contact-form-flex{
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4vw;
        width: 84vw;
    }
    
    .contact-label{
        position: absolute;
        font-size: 5.04vw;
        top: 4.5vw;
        left: 3vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        text-align: end;
        margin-right: 1vw;
        margin-top: 1.15vw;
    }
    
    .contact-input{
        width: 84vw;
        height: 15.6vw;
        border: 1px solid #707070;
        font-size: 5.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
        padding-left: 30vw;
    }
    
    .contact-textarea{
        width: 84vw;
        height: 80.5vw;
        border: 1px solid #707070;
        resize: none;
        font-size: 5.04vw;
        color: #37424B;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        font-weight: 500;
        padding-left: 5vw;
        padding-top: 15vw;
    }
    
    input:focus-visible{
        outline: none;
    }
    
    textarea:focus-visible{
        outline: none;
    }
    
    .flex-send-button{
        width: 86vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .none{
        display: none;
    }
    
    .window-sent{
        width: 84vw;
        height: 120vw;
        background-color: white;
        box-shadow: 0 0 5px #37424B;
        margin-top: 6vw;
        display: flex;
        border-radius: 0.1vw;
        justify-content: center;
        align-items: center;
        font-size: 4.6vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        text-align: center;
        padding: 0;
    }
    
    .btn-send{
        width: 40vw;
        height: 12.5vw;
        background-color: #00A99D;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 4.04vw;
        color: white;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
    }
    
}
