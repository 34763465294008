.years-flex{
    display: flex;
    width: 45vw;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vw;
    border-bottom: 0.5px solid #00A99D;
    padding-bottom: 1vw;
    margin-bottom: 2vw;
}

.year-label{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.btn-years{
    font-size: 1.5vw;
    color: #00A99D;
    width: 2.34vw;
    border-radius: 0.15vw;
    height: 2.34vw;
    border: none;
    box-shadow: 0 0 5px #a1a2a6;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0;
}

.btn-years-disabled{
    font-size: 1.5vw;
    color: #37424B;
    pointer-events: none;
    width: 2.34vw;
    height: 2.34vw;
    border: none;
    box-shadow: 0 0 2px #E8E9EC;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0;
}

.year-btns{
    margin-left: -2vw;
    display: flex;
    justify-content: space-between;
    width: 5.2vw;
    align-items: center;
    margin-bottom: 0.3vw;
}

.aligncenter{
    align-items: center;
}

.bankdata-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.bankdata-card-flex{
    display: flex;
    align-items: center;
    width: 68.75vw;
    margin-bottom: 1vw;
}

.bankdata-card{
    width: 19.875vw;
    height: 4.25vw;
    padding: 1vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 0.5px #707070;
}

.bankdata-card img{
    width: 6.25vw;
    height: 4.16vw;
    object-fit: cover;
    margin-right: 2vw;
}

.bankdata-card h2{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.pdf-flex{
    display: flex;
    margin-left: 2.1vw;
    justify-content: space-between;
    width: 36.5vw;
    align-items: center;
}

.pdf-flex img{
    width: 1.56vw;
    height: 2.08vw;
}

@media only screen and (max-width: 650px) {
    .years-flex{
        display: flex;
        width: 86vw;
        align-items: center;
        justify-content: space-between;
        margin-top: 2vw;
        padding-bottom: 1vw;
        margin-bottom: 2vw;
        border: none;
    }
    
    .year-label{
        font-size: 5.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
    }
    
    .btn-years{
        font-size: 4.5vw;
        color: #00A99D;
        width: 11.25vw;
        border-radius: 1.15vw;
        height: 11.25vw;
        border: none;
        box-shadow: 0 0 5px #a1a2a6;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0;
    }
    
    .btn-years-disabled{
        font-size: 4.5vw;
        color: #37424B;
        pointer-events: none;
        width: 11.25vw;
        height: 11.25vw;
        border: none;
        box-shadow: 0 0 2px #E8E9EC;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0;
    }
    
    .year-btns{
        display: flex;
        justify-content: space-between;
        width: 25vw;
        align-items: center;
        margin-bottom: 0.3vw;
        margin-left: 0;
    }
    
    .aligncenter{
        align-items: center;
    }
    
    .bankdata-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    .bankdata-card-flex{
        display: flex;
        align-items: center;
        width: 86vw;
        margin-bottom: 1vw;
    }
    
    .bankdata-card{
        width:30vw;
        height:20vw;
        padding: 1vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 0 0.5px #707070;
        margin-right: 13vw;
    }
    
    .bankdata-card img{
       width:30vw;
        height:20vw;
        object-fit: cover;
        margin-right: 2vw;
    }
    
    .bankdata-card h2{
        font-size: 1.04vw;
        color: #37424B;
        font-family: "BPG Nino Mtavruli", sans-serif;
        font-weight: 900;
        display: none;
    }
    
    .pdf-flex{
        display: flex;
        margin-left: 2.1vw;
        justify-content: space-between;
        align-items: center;
    }
    
    .pdf-flex img{
        width: 7.5vw;
        height: 10vw;
    }
}