.open-standart-h1{
    font-size: 1.35vw;
    color: #00A99D;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
    margin-top: 3vw;
    margin-bottom: 1.5vw;
    margin-left: 0.5vw;
}

.mail{
    color: #37424B;
    text-decoration: none;
}

.text-pdf-flex{
    display: flex;
    width: 68.75vw;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.125vw;
}

.text-pdf-col{
    text-align: left;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 55vw;
}

.text-pdf-header{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mtavruli", sans-serif;
    font-weight: 900;
}

.text-pdf-date{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 900;
    margin-top: 0.3vw;
}

.text-pdf-p{
    font-size: 1.04vw;
    color: #37424B;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    font-weight: 500;
    margin-top: 0.3vw;
}

.pdf-content-flex{
    width: 10.4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pdf-content-flex img{
    height: 2.39vw;
    width: 2.08vw;
}

@media only screen and (max-width: 650px) {
    
}



