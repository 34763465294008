
    .closed .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
    }
    .closed .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
  
    .opened{
    opacity: 1;
    }
    .opened .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
    }
    .opened .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
      opacity: 0;
    }
  
  .circle-plus {
      height: 2vw;
      width: 2vw;
      font-size: 1vw;
      opacity: .7;
  }
  
  .circle-plus .circle {
      position: relative;
      width: 3vw;
      height: 3vw;
      border-radius: 100%;
      border:none;
  }
  .circle-plus .circle .horizontal {
      position: absolute;
      background-color: red;
      width: 30px;
      height: 5px;
      left: 50%;
      margin-left: -15px;
      top: 50%;
      margin-top: -2.5px;
  }
  .circle-plus .circle .vertical {
      position: absolute;
      background-color: red;
      width: 5px;
      height: 30px;
      left: 50%;
      margin-left: -2.5px;
      top: 50%;
      margin-top: -15px;
  }
  
  