.dot.selected{
    width:  0.729vw!important;
    height: 0.729vw!important;
    background-color: #00A99D!important;
    border-radius: 10px!important;
    box-shadow: none!important;
    border-radius: 35%!important;
}

.dot{
    opacity: 1!important;
    box-shadow: none!important;
    width:  0.729vw!important;
    height: 0.729vw!important;
    border: 2px solid #00A99D;
    border-radius: 35%!important;
}

.slider-wrapper{
    width: 68.75vw!important;
    height: 33.4vw!important;
}

.control-arrow{
  display: none;
}

.control-prev{
    left: -1.2%!important;
}

.control-next{
    right: -1.2%!important;
}

.carousel-root{
    z-index: 1!important;
    margin-top: 4vw;
}

.car-div{
    position: relative;
    width: 99%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.carousel-img{
    width: 45vw!important;
    height: 35.4vw!important;
    object-fit: cover;
}

.carousel-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 23.43vw;
    text-align: start;
}


.underlinenone{
    text-decoration: none;
}

.carousel-img-div{
    position: relative;
}

.carousel-img-header{
    position: absolute;
    width: 100%;
    height: 10vw;
    background-color: rgba(229, 230, 229, .75);
    bottom: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-img-header h2 {
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    font-size: 1.5vw;
}

.underlinenone:hover{
    text-decoration: underline;
    text-decoration-color: #37424B ;
}

.slide{
    min-height: 10vw;
}

.carousel .slider-wrapper{
    min-height: 20vw;
    margin-top: 2vw!important;
}


.carousel-header{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mtavruli", sans-serif;
    width: 20vw;
    margin-top: 7.8vw;
}

.carousel-date{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    width: 19vw;
    margin-top: 0.2vw;
}

.carousel-text{
    color: #37424B;
    font-size: 1.04vw;
    font-family: "BPG Nino Mkhedruli", sans-serif;
    width: 21vw;
    margin-top: 1vw;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carousel .control-dots{
    bottom: 15%!important;
    right: 35%!important;
}



@media only screen and (max-width: 650px) {

    .dot.selected{
        width: 7.5vw!important;
    }

    .carousel .slide img{
        object-fit: cover;
        height: 100vw!important;
    }

    .control-arrow{
      display: none;
    }

    .carousel-content {
        width: 100vw!important;
    }

    .carousel-content label {
        font-weight: 900;
        font-size: 6vw;
    }

    .carousel-content p {
        font-size: 3.9vw;
       
    }

    .carousel-content button {
        font-size: 4.15vw;
        border-radius: 5.8vw;
        width: 35vw;
        height: 10.64vw;
    }

    .dot.selected{
        width:  3.329vw!important;
        height: 3.329vw!important;
        background-color: #00A99D!important;
        border-radius: 10px!important;
        box-shadow: none!important;
        border-radius: 35%!important;
    }
    
    .dot{
        opacity: 1!important;
        box-shadow: none!important;
        width:  3.329vw!important;
        height: 3.329vw!important;
        border: 2.5px solid #00A99D;
        border-radius: 35%!important;
    }
    
    .slider-wrapper{
        width: 100vw!important;
        height: 150vw!important;
    }
    
    .control-arrow{
      display: none;
    }
    
    .control-prev{
        left: -1.2%!important;
    }
    
    .control-next{
        right: -1.2%!important;
    }
    
    .carousel-root{
        z-index: 1!important;
        margin-top: 18vw;
        height: 150vw;
    }
    
    .car-div{
        position: relative;
        width: 100vw!important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column-reverse;
        height: 150vw;
    }
    
    .carousel-img{
        width: 100vw!important;
        object-fit: cover;
        height: 130vw!important;
    }
    
    .carousel-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 86vw;
        text-align: center;
    }
    
    
    .underlinenone{
        text-decoration: none;
    }
    
    .underlinenone:hover{
        text-decoration: underline;
        text-decoration-color: #37424B ;
    }
    
    .slide{
        min-height: 10vw;
        width: 100vw!important;
    }
    
    .carousel .slider-wrapper{
        min-height: 20vw;
        margin-top: 2vw!important;
    }
    
    
    .carousel-header{
        color: #37424B;
        font-size: 4.5vw;
        font-family: "BPG Nino Mtavruli", sans-serif;
        width: 86vw;
        margin-top: 10vw;
    }
    
    .carousel-date{
        color: #37424B;
        font-size: 4.04vw;
        font-family: "BPG Nino Mkhedruli", sans-serif;
        width: 86vw;
        margin-top: 0.2vw;
    }
    
    .carousel-text{
      display: none;
    }
    
    .carousel .control-dots{
        bottom: 25%!important;
        right: 0%!important;
    }
    

}